import React, { lazy, Suspense } from "react";

const LazyExampleComponent = lazy(() => import("./ExampleComponent"));

export function ExampleComponent(props) {
  return (
    <Suspense fallback={null}>
      <LazyExampleComponent {...props} />
    </Suspense>
  );
}

const LazyDocumentWebhooksAccess = lazy(
  () => import("./document-webhooks-access")
);

export function DocumentWebhooksAccess(props) {
  return (
    <Suspense fallback={null}>
      <LazyDocumentWebhooksAccess {...props} />
    </Suspense>
  );
}
